import React from 'react';

const Contact = () => (
  <section className="section">
    <div className="container has-text-centered">
      <h1 className="title">Contact</h1>
      <p>
        Find me on{' '}
        <a
          href="https://www.linkedin.com/in/lauracalabro"
          target="_blank"
          rel="noopener noreferrer"
        >
          Linkedin
        </a>
        .
      </p>
    </div>
  </section>
);

export default Contact;
